<template>
  <div>
    <b-card>
      <!-- form -->
      <b-form>

        <b-row>
          <!-- full name -->
          <b-col md="6">
            <b-form-group
              label="Full Name"
              label-for="fullname"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="fullname"
                  v-model="userFullName"
                  name="fullname"
                  type="text"
                  placeholder="Full Name"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ full name -->

          <!-- username -->
          <b-col md="6">
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="username"
                  v-model="username"
                  name="username"
                  type="text"
                  :disabled="true"
                  :placeholder="username"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ username -->
        </b-row>

        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-new-password"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  name="new-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="changeUserData()"
            >
              Save changes
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
        
      </b-form>
    </b-card>

    <b-card>
      <h4>Notification settings</h4>
      <small>Select which notifications you want to receive via email</small>
      <div class="mt-2">
        <b-form-checkbox-group
          v-model="selectedNotifications"
          :options="notifications"
          switches
          stacked
          @change="changeNotificationSettings()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckboxGroup
  },
  directives: {
    Ripple,
  },
  created() {
    this.$http.get("/api/auth/notification-types")
      .then((res) => {
        if(res != undefined) {
          let allNotifications = []
          for(let i=0; i<res.data.length; i++) {
            allNotifications.push({ "text" : res.data[i].notification_description, "value" : res.data[i].id })
          }
          this.notifications = allNotifications
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  mounted() {
    const userData = store.state.app.userData;
    this.userFullName = userData.name;
    this.username = userData.username;

    this.$http.get("/api/auth/user/notifications-settings")
    .then((res) => {
      if(res != undefined) {
        let allSelectedNotifications = []
        for(let i=0; i<res.data.length; i++) {
          allSelectedNotifications.push(res.data[i].id)
        }
        this.selectedNotifications = allSelectedNotifications
      }
    })
    .catch((err) => {
      console.log(err)
      this.$toast({
        component: ToastificationContent,
        props: {
            title: "Something went wrong. Please try login again",
            icon: "AlertCircleIcon",
            variant: "danger",
        },
      });
    })
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      userFullName: "",
      username: "",
      notifications: [],
      selectedNotifications: [],
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changeUserData() {
      let data = {
        "name": ""
      }

      if(this.userFullName == "" || this.userFullName == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
            "Full name must not be empty!",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }

      data.name = this.userFullName;

      // if any of the password fields are not empty, check if they are all filled
      if(this.passwordValueOld != "" || this.newPasswordValue != "" || this.RetypePassword != "") {
        
        // check if all fields are not empty
        if(this.passwordValueOld != "" && this.newPasswordValue != "" && this.RetypePassword != "") {        

          // check if the new password and password retype match
          // if they match, set the values
          if(this.newPasswordValue == this.RetypePassword) {
            data.password = this.newPasswordValue;
            data.c_password = this.RetypePassword;
            data.current_password = this.passwordValueOld;
          } else {
            this.$toast({
            component: ToastificationContent,
              props: {
                title: "New password and password retype don't match",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
            return;
          }

        } else {
          // not all fields are filled
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "All password fields are required when changing your password!",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          return;
        }
      }

      this.$http.patch("/api/auth/user", data)
        .then((res) => {
          if(res != undefined) {
            // Set user data
            let userData = res.data.user;
            userData.avatar = this.makeAvatar(userData.username)
            store.commit("app/setUserData", userData);

            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                "Profile info successfully updated !",
                  icon: "CheckSquareIcon",
                  variant: "success",
              },
            });

            this.RetypePassword = "";
            this.newPasswordValue = "";
            this.passwordValueOld = "";
          }
        })
        .catch((error) => {
          console.log(error)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    changeNotificationSettings() {
      let notifications = {
        "notifications": this.selectedNotifications
      }

      this.$http.patch("/api/auth/user/notifications-settings", notifications)
      .then((res) => {
          this.$toast({
              component: ToastificationContent,
              props: {
                  title: "Notification settings successfully updated",
                  icon: "CheckSquareIcon",
                  variant: "success",
              },
          });
      })
      .catch((error) => {
          console.log(error)

          this.$toast({
              component: ToastificationContent,
              props: {
                  title: "There was an error!",
                  icon: "AlertCircleIcon",
                  variant: "danger",
              },
          });

      });
    }
  },
}
</script>
